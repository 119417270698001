<template>
    <div class="wrapper register-wrapper">
        <div class="register-page">
            <div class="container p-0">
                <span class="back-button" v-if="currStep < 7">
                    <button id="bckBtn" name="bckBtn" class="pull-left" v-if="currStep > 1" @click="bckBtn"
                        :disabled="regBusy">
                        <i class="fa fa-angle-left"></i>
                        <!-- <span>{{ $t('register.back') }}</span> -->
                    </button>
                    <button id="bckBtn" name="bckBtn" class="pull-left" v-else @click="redirect('/')">
                        <i class="fa fa-angle-left"></i>
                        <!-- <span>{{ $t('register.home') }}</span> -->
                    </button>
                </span>
                <div class="main-title">
                    <h3 class="mb-0">{{ $t('register.register_title') }}</h3>
                </div>
                <div class="card card-pages">
                    <div class="form-title">
                        <h4>Welcome to Cheap Comforts</h4>
                    </div>
                    <div class="card-body">
                        <div class="row" v-if="regMsgTextErr != ''">
                            <div class="col-md-12 form-group md-form">
                                <div class="alert alert-danger">{{ regMsgTextErr }}</div>
                            </div>
                        </div>
                        <div class="row" v-if="regMsgTextSucc != ''">
                            <div class="col-md-12 form-group md-form">
                                <div class="alert alert-success">{{ regMsgTextSucc }}</div>
                            </div>
                        </div>

                        <div class="form-fields" v-if="currStep == 1">
                            <!-- <div class="col-md-12 form-group md-form">
                                <h5 class="text-center">{{ $t('register.name') }}</h5>
                            </div> -->
                            <div class="form-group">
                                <!-- <label for="regFname">{{ $t('register.name') }} <span class="text-red">*</span></label> -->
                                <input id="regFname" name="regFname" placeholder="Name *" type="text"
                                    class="form-control" autocomplete="off" v-model="regFname">
                            </div>
                            <div class="form-group">
                                <!-- <label for="regEmail">{{ $t('register.email') }} <span class="text-red">*</span></label> -->
                                <input id="regEmail" name="regEmail" placeholder="Email *" type="text"
                                    class="form-control" autocomplete="off" v-model="regEmail">
                            </div>
                            <div class="form-group">
                                <!-- <label for="regPhone">{{ $t('register.phone') }} <span class="text-red">*</span></label> -->
                                <input id="regPhone" name="regPhone" maxlength="16" @input="enforcePhoneFormat()"
                                    type="tel" placeholder="(123) 456-7890" class="form-control" autocomplete="off"
                                    v-model="regPhone">
                            </div>
                        </div>
                        <div class="form-fields" v-if="currStep == 2">
                            <!-- <div class="col-md-12 form-group md-form">
                                <h5 class="text-center">{{ $t('register.set_password') }}<br><small class="text-center">{{ $t('register.password_length') }}</small></h5>
                            </div> -->
                            <div class="form-group">
                                <!-- <label for="regPassword">{{ $t('register.password') }} <span class="text-red">*</span></label> -->
                                <div class="input-group">
                                    <input id="regPassword" name="regPassword" :type="type" class="form-control"
                                        autocomplete="off" v-model="regPassword">
                                    <div class="input-group-append" @click="showPassword">
                                        <span class="input-group-text"><i :class="btnText"></i></span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <!-- <label for="regCpassword">{{ $t('register.confirm_password') }} <span class="text-red">*</span></label> -->
                                <input id="regCpassword" name="regCpassword" type="password" class="form-control"
                                    autocomplete="off" v-model="regCpassword">
                            </div>
                        </div>
                        <div class="row mt-3" v-if="currStep < 7">
                            <div class="col-md-12 form-group md-form">
                                <button id="regSub" name="regSub" class="btn btn-primary pull-right" @click="regSub"
                                    :disabled="regBusy">{{ regBtnText }}</button>
                            </div>
                        </div>
                        <div class="row" v-if="currStep == 7">
                            <div class="col-md-12 form-group md-form">
                                <button id="regSingIn" name="regSingIn" class="btn btn-primary pull-right"
                                    @click="regSingIn">{{ $t('register.sign_in') }}</button>
                            </div>
                        </div>
                        <div class="social-login text-center">
                            <button class="btn" @click="AuthProvider('google')"><i
                                    style="background: linear-gradient(-120deg, #4285f4, #34a853, #fbbc05, #ea4335);-webkit-background-clip: text;-webkit-text-fill-color: transparent;"
                                    class="fa fa-google" aria-hidden="true"></i> <span>Login with Google</span></button>
                            <button class="btn" @click="AuthProvider('facebook')"><i style="color: #1877F2;"
                                    class="fa fa-facebook" aria-hidden="true"></i> <span>Login with
                                    Facebook</span></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
// Tooltips Initialization
import API from '@/api'
export default {
    name: 'RegisterComponent',
    data: function () {
        return {
            email: '',
            password: '',
            repeatPassword: '',
            errorMsg: '',
            show: false,
            successShow: false,
            regBusy: false,
            regFname: '',
            regBirthday: '',
            regUsername: '',
            regPassword: '',
            regCpassword: '',
            regEmail: '',
            regPhone: '',
            currStep: 1,
            regMsgTextErr: '',
            regMsgTextSucc: '',
            regBtnText: this.$t('register.next'),
            type: 'password',
            btnText: 'fa fa-eye',
        }
    },

    methods: {
        redirect(path) {
            //window.location.href = '/';
            this.$router.push({ path: path });
            // Bus.$emit('route-changes');

        },
        enforcePhoneFormat() {
            let x = this.regPhone
                .replace(/\D/g, "")
                .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);

            this.regPhone = !x[2]
                ? x[1]
                : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
        },
        regSub() {
            this.regMsgTextErr = '';
            if (this.currStep == 1) {
                if (this.regFname == '') {
                    this.regMsgTextErr = this.$t('validations.name');
                } else if (this.regEmail == '') {
                    this.regMsgTextErr = this.$t('validations.email');
                } else if (this.regPhone == '' || this.regPhone.length != 14) {
                    this.regMsgTextErr = this.$t('validations.phone');
                } else {
                    var re1 = /\S+@\S+\.\S+/;
                    if (re1.test(this.regEmail)) {
                        this.currStep = 2;
                        this.regBtnText = this.$t('register.next');

                    } else {
                        this.regMsgTextErr = this.$t('validations.v_email');
                    }
                }
            } else if (this.currStep == 2) {
                this.regBtnText = this.$t('register.sign_up');
                if (this.regPassword == '') {
                    this.regMsgTextErr = this.$t('validations.password');
                } else if (this.regPassword.length < 6) {
                    this.regMsgTextErr = this.$t('validations.min_password');
                } else if (this.regCpassword == '') {
                    this.regMsgTextErr = this.$t('validations.password');
                } else if (this.regPassword != this.regCpassword) {
                    this.regMsgTextErr = this.$t('validations.match_pass');
                } else {
                    this.regBtnText = this.$t('register.signing_up');
                    this.register();
                }
            }
        },
        ageValidation(birthYear) {
            const dateBirth = new Date(birthYear);
            const today = new Date();
            const diff = new Date(today.getTime() - dateBirth.getTime());
            const age = diff.getUTCFullYear() - 1970;
            return age;
        },
        bckBtn() {
            if (this.currStep > 1) {
                this.regMsgTextErr = '';
                this.regBtnText = this.$t('register.next');
                this.currStep = this.currStep - 1;
            }
        },
        showPassword() {
            if (this.type === 'password') {
                this.type = 'text'
                this.btnText = 'fa fa-eye-slash'
            } else {
                this.type = 'password'
                this.btnText = 'fa fa-eye'
            }
        },
        register_first() {
            this.$v.$touch();
        },

        register() {

            let data = {
                name: this.regFname,
                email: this.regEmail,
                password: this.regPassword,
                phone: this.regPhone,
                user: 'guest'
            };
            API.registerUser(
                data,
                data => {
                    if (data.token) {
                        this.$router.push("/home/login");
                    } else {
                        this.$swal({
                            type: 'error',
                            title: 'Oops...',
                            text: data.message,
                        })
                    }
                    this.isDisabled = false;
                },
                err => {
                    this.isDisabled = false;
                    this.$swal({
                        type: 'error',
                        title: 'Oops...',
                        text: err.errors['email'],
                    })
                }
            )
        },
        regSingIn() {
            window.location.href = "/login";
        },
        googleLogin() {
            window.location.href = "/redirect-google";
        },
        facebookLogin() {
            window.location.href = "/redirectfb";
        },
        instagramLogin() {
            window.location.href = "/redirectinsta";
        },
        AuthProvider(provider) {
            var self = this;

            this.$auth
                .authenticate(provider)
                .then((response) => {
                    // self.SocialLogin(provider, response);
                })
                .catch((err) => {
                    if (err.message === "Auth popup window closed") {
                        this.showError = true;
                        this.error = "Authentication was cancelled. Please try again.";
                    } else {
                        console.error(err);
                    }
                });

            const handleAuthMessage = (event) => {
                if (event.data.type === 'authSuccess') {
                    const userEmail = event.data.email;
                    window.removeEventListener('message', handleAuthMessage); // Remove listener after handling message
                    // this.$router.push({ name: 'home' });
                    let data = {
                        email: event.data.email,
                        password: 'password',
                    }
                    API.loginAsGuest(
                        data,
                        data => {
                            if (typeof data.token !== "undefined") {
                                localStorage.setItem('isLoggedin', data.token)
                                localStorage.setItem('loginwithfb', '1')
                                localStorage.setItem('client_settings', JSON.stringify(data.client_settings))
                                localStorage.setItem('user', JSON.stringify(data.user))
                                localStorage.setItem('userPermissions', JSON.stringify(data.permission))
                                localStorage.setItem('password', this.password)
                                if (this.remember_be == 'true') {
                                    localStorage.setItem('email', this.email)
                                    localStorage.setItem('password', this.password)
                                }
                                // this.$router.push({ name: 'home' });
                                const originalUrl = localStorage.getItem('originalUrl');
                                if (originalUrl) {
                                    // If original URL exists, redirect back to it
                                    this.$router.push(originalUrl);
                                    localStorage.removeItem('originalUrl'); // Remove the original URL from storage
                                } else {
                                    // If no original URL, redirect to a default route
                                    this.$router.push({ name: 'home' });
                                }
                            } else {
                                this.$swal({
                                    type: 'error',
                                    title: 'Oops...',
                                    text: data.message,
                                })
                            }
                        },
                        err => {
                            this.$swal({
                                type: 'error',
                                title: 'Oops...',
                                text: err.message,
                            })
                        }

                    )

                } else if (event.data === 'authError') {
                    window.removeEventListener('message', handleAuthMessage); // Remove listener after handling message
                    this.showError = true;
                    this.error = "Social login failed. Please try again.";
                }
            };

            window.addEventListener('message', handleAuthMessage);
        },
    },
    mounted() {

    },
    beforeCreate() {
        //   location.reload();
    },
    created() {

    }
};
</script>

<style scoped>
.client-dashbboard .main-panel .content-wrapper .wrapper {
    position: relative;
}

.wrapper.register-wrapper {
    height: 92vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.register-page {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 450px;
    margin: 0 auto;
    border-radius: 10px;
    box-shadow: 0 0 5px 0px #0000007a;
    padding-top: 25px;
    padding-bottom: 10px;
    position: relative;
}

.register-page .main-title {
    text-align: center;
    padding: 0 0 15px 5px;
    border-bottom: 1px solid #adadad;
    margin-bottom: 20px;
}

.register-page .main-title h3 {
    line-height: 1.2;
    font-size: 20px;
    font-weight: 700;
}

.register-page .card {
    border: 0;
    padding: 0 20px;
}

.register-page .card-body {
    padding: 0;
}

.register-page .card .form-title {
    margin: 10px 0 10px;
}

.steps-form-2 {
    display: table;
    width: 100%;
    position: relative;
}

.steps-form-2 .steps-row-2 {
    display: table-row;
}

.steps-form-2 .steps-row-2:before {
    top: 14px;
    bottom: 0;
    position: absolute;
    content: " ";
    width: 100%;
    height: 2px;
    background-color: #7283a7;
}

.steps-form-2 .steps-row-2 .steps-step-2 {
    display: table-cell;
    text-align: center;
    position: relative;
}

.steps-form-2 .steps-row-2 .steps-step-2 p {
    margin-top: 0.5rem;
}

.steps-form-2 .steps-row-2 .steps-step-2 button[disabled] {
    opacity: 1 !important;
    filter: alpha(opacity=100) !important;
}

.steps-form-2 .steps-row-2 .steps-step-2 .btn-circle-2 {
    width: 70px;
    height: 70px;
    border: 2px solid #59698D;
    background-color: white !important;
    color: #59698D !important;
    border-radius: 50%;
    padding: 22px 18px 15px 18px;
    margin-top: -22px;
}

.steps-form-2 .steps-row-2 .steps-step-2 .btn-circle-2:hover {
    border: 2px solid #4285F4;
    color: #4285F4 !important;
    background-color: white !important;
}

.steps-form-2 .steps-row-2 .steps-step-2 .btn-circle-2 .fa {
    font-size: 1.7rem;
}


@media only screen and (max-width: 575px) {
    .steps-form-2 .steps-row-2 .steps-step-2 .btn-circle-2 {
        width: 50px !important;
        height: 50px !important;
        padding: 14px 18px 15px 12px !important;
        margin-top: -11px !important;
    }

    .steps-form-2 .steps-row-2 .steps-step-2 .btn-circle-2 .fa {
        font-size: 1.3rem !important;
    }
}


.jumbotron {
    background-color: #fff !important;
    border-radius: .125rem !important;
    -webkit-box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%) !important;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%) !important;
}

.register-page .skin-light.color-grey-light,
.register-page .skin-light .color-grey-light,
.register-page .skin-light .badge-grey-light,
.register-page .skin-light .bg-grey-light {
    background-color: #fbfbfb !important;
}

.register-page .skin-light .mt-70 {
    margin-top: 70px !important;
}

.register-page .skin-light .jumbotron {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border-radius: 0 !important;
    padding: 0 !important;
    width: 100%;
    height: 129px;
}

.register-page .skin-light {
    color: #4f4f4f;
}

.register-page .msg {
    margin: 0px 14px !important;
}

.register-page input[type=text],
.register-page input[type=date],
.register-page input[type=password],
.register-page input[type=tel] {
    border: none;
    padding: 4px 10px !important;
    border: 1px solid #a3a1a1;
    border-radius: 0;
    margin-bottom: 0 !important;
    margin-top: 0px !important;
}

.register-page input[type=text]:focus,
.register-page input[type=date]:focus,
.register-page input[type=password]:focus,
.register-page input[type=tel]:focus {
    border: 2px #42aaff solid !important;
}

.register-page .form-group label {
    font-weight: 400 !important;
}

.register-page button#regSub,
.register-page button#bckBtn {
    font-size: 14px !important;
}

.register-page button#regSub {
    width: 100%;
    border-radius: 10px !important;
}

.register-page .form-group h5 small {
    font-size: 12px !important;
    font-weight: 300 !important;
}

.register-page .input-group .input-group-append span.input-group-text {
    height: 46px !important;
    background-color: #fff !important;
    border: 0;
    border-bottom: 1px solid #a3a1a1;
    border-left: 1px solid #a3a1a1;
    border-radius: 0 10px 0 0;
}

.register-page .input-group .input-group-append {
    margin-left: 0;
}

.register-page span.ppcls {
    font-size: 12px !important;
    text-align: justify !important;
    line-height: 1.4 !important;
    margin-bottom: 15px;
    margin-top: 5px;
}

.register-page span.bckBtntxt {
    margin-left: 5px;
}

.register-page .left-card {
    border-right: none !important;
}

.social-menu {
    margin-left: 220px;
    margin-top: 30px;
}

.social-menu ul {
    /* position: absolute; */
    top: 50%;
    left: 50%;
    padding: 0;
    margin: 0;
    transform: translate(-50%, -50%);
    display: flex;
}

.social-menu ul li {
    list-style: none;
    margin: 0 15px;
}

.social-menu ul li .fab {
    font-size: 30px;
    line-height: 60px;
    transition: .3s;
    color: #000;
}

.social-menu ul li .fab:hover {
    color: #fff;
}

.social-menu ul li button {
    position: relative;
    display: block;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #fff;
    text-align: center;
    transition: .6s;
    box-shadow: 0 5px 4px rgba(0, 0, 0, .5);
}

.social-menu ul li button:hover {
    transform: translate(0, -10%);
}

.social-menu ul li:nth-child(1) button:hover {
    /* background-color: rgba(0, 0, 0, 0.829); */
    background-color: #E4405F;
}

.social-menu ul li:nth-child(2) button:hover {
    /* background-color: #E4405F; */
    background-color: #0857dc;
}

.social-menu ul li:nth-child(3) button:hover {
    /* background-color: #0077b5; */
    background-color: #ec4333
}

.social-menu ul li:nth-child(4) button:hover {
    /* background-color: #000; */
    background-color: #1da1f2;
}

.icons-row {
    align-items: center;
    justify-content: center;
}

.icons-row .social-menu {
    margin-left: 0;
    margin-top: 0;
    width: 100%;
}

.icons-row .social-menu ul {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
    transform: none;
    top: 0;
    left: 0;
}

.back-button {
    position: absolute;
    left: 20px;
}

.register-page .back-button button#bckBtn {
    font-size: 18px !important;
    border: 2px solid #0000007a;
    border-radius: 100px;
    width: 26px;
    background: #fff;
    height: 26px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
}

.register-page .social-login {
    margin-top: 30px;
}

.register-page .social-login .btn {
    width: 100%;
    margin-bottom: 10px;
    font-weight: 500;
    border-radius: 10px !important;
    display: flex;
    border: 1px solid #000;
}

.register-page .social-login .btn span {
    flex: 1 1 0% !important;
}

.register-page .card-body .form-fields .form-group .form-control {
    padding: 0;
    border: 0 !important;
}

.register-page .card-body .form-fields {
    border: 1px solid #a3a1a1;
    border-radius: 10px;
    overflow: hidden;
}

.register-page .card-body .form-fields .form-group:first-child .form-control {
    border-bottom: 1px solid #a3a1a1 !important;
    border-radius: 10px 10px 0 0;
}

.register-page .card-body .form-fields .form-group:nth-child(2) .form-control {
    border-bottom: 1px solid #a3a1a1 !important;
    border-radius: 10px 10px 0 0;
}

.register-page .card-body .form-fields .form-group:last-child .form-control {
    border-radius: 0 0 10px 10px;
}

.register-page .form-group {
    margin-bottom: 0;
}

@media (max-width: 991px) {
    .skin-light .mt-70 {
        margin-top: 150px !important;
    }
}

@media (min-width: 992px) {
    .skin-light .mt-70 {
        margin-top: 80px !important;
    }
}
</style>